<script setup>
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

defineOptions({ name: 'Image', inheritAttrs: true })

const props = defineProps({
  alt: { type: String, default: 'An un-captioned image' },
  class: [String, Array, Object],
  src: { type: String, required: true },
})

const page = usePage()

const baseUrl = (page.props?.assetUrl || location.protocol + '//' + location.host)

const actualSrc = computed(() => {
  const src = props.src.replace(/\/?(.+)/, '$1')

  if (src.startsWith('http')) return src

  return `${baseUrl}/${src}`
})
</script>

<template>
  <img
    :src="actualSrc"
    :alt="$props.alt"
    :class="$props.class"
  >
</template>
